<template>
    <div>
        <b-card no-body>
            <template v-if="!readOnly">
                <Header />
                <hr class="mb-2" />
            </template>
        </b-card>

        <div class="py-4">
            <div class="d-flex justify-content-between">
                <h4 class="mb-2">
                    Applicant:
                    <span style="font-weight: 400">{{ $buildName(loanApplication.applicantInfo) }}</span>
                </h4>

                <b-button
                    variant="primary"
                    @click="handleSubmit"
                    :disabled="loading"
                    v-if="
                        $validatePermission('/LoanApplications-SubmitForJobVerification') &&
                        loanApplication.loanProcessStatusLog.currentStatusCode != '220'
                    "
                >
                    <b-spinner small v-if="loading" />
                    <span v-else>Submit for job verification</span>
                </b-button>
            </div>
            <JobVerificationDetail type="applicant" />
        </div>

        <div class="p-4" v-if="loanApplication.coApplicantInfo.id">
            <h4 class="mb-2">
                Co Applicant:
                <span style="font-weight: 400">{{ $buildName(loanApplication.coApplicantInfo) }}</span>
            </h4>

            <JobVerificationDetail type="coApplicant" />
        </div>

        <div v-if="loanApplication.guarantorInfo.id" class="p-4">
            <h4 class="mb-2">
                Guarantor:
                <span style="font-weight: 400">{{ $buildName(loanApplication.guarantorInfo) }}</span>
            </h4>
            <JobVerificationDetail type="guarantor" />
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Header from '@/components/Header.vue';
import JobVerificationDetail from './job-verification/JobVerificationDetail.vue';

export default {
    name: 'ApplicationJobVerification',
    components: {
        Header,
        JobVerificationDetail,
    },
    props: {
        new: Boolean,
        readOnly: Boolean,
    },
    data: () => ({
        page: {
            title: 'employment',
        },
        loading: false,
    }),
    methods: {
        async handleSubmit() {
            this.loading = true;
            await this.$store.dispatch(
                'customer/employments/submit_recordsVerification',
                this.loanApplication.applicantInfo.id,
            );

            if (this.loanApplication.coApplicantInfo.id) {
                await this.$store.dispatch(
                    'customer/employments/submit_recordsVerification',
                    this.loanApplication.coApplicantInfo.id,
                );
            }

            if (this.loanApplication.guarantorInfo.id) {
                await this.$store.dispatch(
                    'customer/employments/submit_recordsVerification',
                    this.loanApplication.guarantorInfo.id,
                );
            }
            await this.$store.dispatch('loan/applications/refreshApplicantsInfo', this.$route.params.id);

            this.loading = false;
        },
    },
    computed: {
        ...mapGetters({
            authorizations: 'auth/getAuthorizations',
            loanApplication: 'loan/applications/getLoanApplication',
        }),
        hasPermission() {
            return this.authorizations.includes('/LoanApplications-Job-Verification');
        },
    },
};
</script>
