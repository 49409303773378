<template>
    <b-row>
        <b-col cols="12">
            <b-card no-body class="mb-4">
                <b-card-body class="p-3">
                    <Header />
                    <hr class="mb-4" />
                    <div class="row">
                        <div class="col-6">
                            <h4>Disbursement Detail</h4>
                            <div class="row">
                                <div class="col-6">
                                    <p>Disbursement Recipient</p>
                                    <p>Bank Name</p>
                                    <p>Bank Account Number</p>
                                </div>
                                <div class="col-6">
                                    <!-- Select with applicants -->
                                    <b-select
                                        v-model="disbursementRecipient"
                                        required="required"
                                        class="mb-2"
                                        @change="handleDisbursement"
                                    >
                                        <option :value="-1">Please select an option</option>
                                        <option v-for="option in applicants" :key="option.id" :value="option.id">
                                            {{ option.firstName }} {{ option.lastName }}
                                        </option>
                                    </b-select>
                                    <p>{{ disbursementBank }}</p>
                                    <p>{{ disbursementAccount }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <h4>Repayment Methosd Details</h4>
                            <div class="row">
                                <div class="col-6">
                                    <p>Repayment Method</p>
                                    <p>Repayment Document Required</p>
                                    <template v-if="repaymentMethod == 'POB'">
                                        <p>POB Tied to</p>
                                        <p>Employer for POB</p>
                                    </template>
                                </div>
                                <div class="col-6">
                                    <p>{{ repaymentMethod }}</p>
                                    <b-form-checkbox :checked="repaymentMethod == 'POB'" class="mb-2" disabled />

                                    <template v-if="repaymentMethod == 'POB'">
                                        <b-select
                                            v-model="pobtiedTo"
                                            required="required"
                                            class="mb-2"
                                            @change="handleTied"
                                        >
                                            <option :value="-1">Please select an option</option>
                                            <option v-for="option in applicants" :key="option.id" :value="option.id">
                                                {{ option.firstName }} {{ option.lastName }}
                                            </option>
                                        </b-select>

                                        <b-select
                                            v-model="employerForPob"
                                            required="required"
                                            class="mb-2"
                                            @change="updateSignature"
                                        >
                                            <option :value="-1">Please select an option</option>
                                            <option
                                                v-for="option in employments"
                                                :key="option.employerId"
                                                :value="option.employerId"
                                            >
                                                {{ option.employerName }}
                                            </option>
                                        </b-select>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <h4>Disbursement Breakdown</h4>
                        <!-- Table with columns: Type, Bank Name, BankAccount, company Name,Company Bank Account, Transfer Amount -->
                        <b-table
                            :items="[
                                {
                                    type: 'Direct',
                                    bankName: this.loanApplication.applicantInfo.additionalDetails.bankName,
                                    bankAccount: this.loanApplication.applicantInfo.additionalDetails.bankAccountNumber,
                                    companyName: '',
                                    companyBankAccount: '',
                                    transferAmount: $formatCurrency(this.loanApplication.freshFunds),
                                },
                            ]"
                            :fields="[
                                { key: 'type', label: 'Type' },
                                { key: 'bankName', label: 'Bank Name' },
                                { key: 'bankAccount', label: 'Bank Account' },
                                { key: 'companyName', label: 'Company Name' },
                                { key: 'companyBankAccount', label: 'Company Bank Account' },
                                { key: 'transferAmount', label: 'Transfer Amount' },
                            ]"
                            responsive
                            small
                            class="mb-0"
                            head-variant="light"
                            hover
                            show-empty
                            empty-text="No data available"
                        />
                    </div>

                    <!-- Sign options -->
                    <div>
                        <div class="d-flex justify-content-between align-items-center mb-3 mt-3">
                            <h4>Sign Process</h4>
                            <b-button
                                v-if="hasPermissionSign && signProcess.length == 0"
                                :variant="'primary'"
                                :disabled="loadingSignProcess"
                                @click="createSignProcess"
                            >
                                <b-spinner small v-if="loadingSignProcess"></b-spinner>
                                Start Process
                            </b-button>
                        </div>
                        <div class="">
                            <b-form-group v-slot="{ ariaDescribedby }">
                                <label>Type of signature selected</label>
                                <b-form-radio-group
                                    v-model="signSelected"
                                    :options="[
                                        { text: 'Digital Sign', value: 'digital' },
                                        { text: 'Manual Sign', value: 'manual' },
                                    ]"
                                    :aria-describedby="ariaDescribedby"
                                    name="sign"
                                    @change="updateSignature"
                                    :disabled="signProcess.length > 0"
                                ></b-form-radio-group>
                            </b-form-group>

                            <div style="display: grid; gap: 16px; grid-template-columns: repeat(3, 1fr)" class="mb-4">
                                <div class="col-span-4">
                                    <label>Document for the applicant's signature</label>
                                    <b-select
                                        required="required"
                                        v-model="applicantDocumentTypeIdForSign"
                                        @change="updateSignature"
                                    >
                                        <option :value="-1">Please select an option</option>
                                        <option v-for="option in options" :key="option.value" :value="option.value">
                                            {{ option.text }}
                                        </option>
                                    </b-select>
                                </div>
                                <div v-if="loanApplication.coApplicantInfo.id != 0" class="col-span-4">
                                    <label>Document for the co-applicant's signature</label>
                                    <b-select
                                        required="required"
                                        v-model="coApplicantDocumentTypeIdForSign"
                                        @change="updateSignature"
                                    >
                                        <option :value="-1">Please select an option</option>
                                        <option v-for="option in options" :key="option.value" :value="option.value">
                                            {{ option.text }}
                                        </option>
                                    </b-select>
                                </div>
                                <div v-if="loanApplication.guarantorInfo.id != 0" class="col-span-4">
                                    <label>Document for the guarantor's signature</label>
                                    <b-select
                                        required="required"
                                        v-model="guarantorDocumentTypeIdForSign"
                                        @change="updateSignature"
                                    >
                                        <option :value="-1">Please select an option</option>
                                        <option v-for="option in options" :key="option.value" :value="option.value">
                                            {{ option.text }}
                                        </option>
                                    </b-select>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex justify-content-end align-items-center mb-3 mt-3" style="gap: 16px">
                            <b-button variant="primary" @click="printContract" :disabled="loadingPrinting">
                                Print Contract
                            </b-button>
                            <b-button
                                v-if="loanApplication.loanProcessStatusLog.currentStatusCode != '220'"
                                variant="secondary"
                                @click="$bvModal.show('modal-upload-file')"
                            >
                                Upload File
                            </b-button>
                        </div>

                        <b-table
                            show-empty
                            responsive
                            small
                            class="mb-0"
                            head-variant="light"
                            :items="signProcess"
                            :fields="[
                                { key: 'id', label: 'ID' },
                                { key: 'code', label: 'Code' },
                                { key: 'description', label: 'Description' },
                                { key: 'createdDate', label: 'Create Date' },
                                { key: 'actions', label: 'Actions' },
                            ]"
                            hover
                        >
                            <template v-slot:cell(code)="data">
                                {{ data.item.contractStatusType.code }}
                            </template>
                            <template v-slot:cell(description)="data">
                                {{ data.item.contractStatusType.displayMessage }}
                            </template>
                            <template v-slot:cell(createdDate)="data">
                                {{ $formatDate(data.item.createdDate) }}
                            </template>

                            <template v-slot:cell(actions)="data">
                                <b-button variant="primary" @click="viewDocument(data.item)" class="btn-sm" size="sm">
                                    View
                                </b-button>
                            </template>
                        </b-table>
                    </div>
                </b-card-body>
            </b-card>
        </b-col>

        <b-col cols="12">
            <b-modal id="modal-templates" hide-footer centered title="Document templates">
                <b-card-text>
                    <div
                        class="border-bottom d-flex justify-content-between py-2"
                        v-for="template in templates"
                        :key="template.id"
                    >
                        <h6 class="message-title text-primary mb-0 mt-1">{{ template.name }}</h6>
                        <ContractReport v-if="template.id == 1" :data="dataReport" />
                    </div>
                </b-card-text>
            </b-modal>

            <!-- Modal Upload File -->
            <b-modal id="modal-upload-file" hide-footer centered title="Upload signed document">
                <b-card-text>
                    <form @submit.prevent="uploadFile">
                        <b-form-file
                            v-model="file"
                            placeholder="Choose a file or drop it here..."
                            drop-placeholder="Drop file here..."
                            required
                        ></b-form-file>
                        <b-button type="submit" variant="primary" class="mt-3">Upload</b-button>
                    </form>
                </b-card-text>
            </b-modal>

            <!-- Modal Delete confirmation -->
            <b-modal id="modal-delete" hide-footer centered title="Delete Confirmation">
                <b-card-text>
                    <p>Are you sure you want to delete this document?</p>
                </b-card-text>
                <div class="d-flex justify-content-end" style="gap: 8px">
                    <b-button variant="secondary" @click="$bvModal.hide('modal-delete')">Cancel</b-button>
                    <b-button variant="danger" @click="deleteDocument">Delete</b-button>
                </div>
            </b-modal>

            <b-modal id="modal-view-file" hide-footer centered title="View File">
                <b-card-text v-if="fileSelected">
                    <h4>{{ fileSelected.name }}</h4>
                    <div style="overflow: auto; width: 600px; height: 500px">
                        <iframe frameborder="0"></iframe>
                    </div>
                </b-card-text>
            </b-modal>
        </b-col>
    </b-row>
</template>

<script>
import Header from '@/components/Header.vue';
import { mapGetters } from 'vuex';
import ContractReport from '../reports/ContractReport.vue';
export default {
    name: 'ApplicationDigitalSign',
    components: {
        Header,
        ContractReport,
    },

    data: () => ({
        fields: [
            { key: 'id', label: 'ID' },
            { key: 'name', label: 'Name' },
            { key: 'createDate', label: 'Create Date' },
            { key: 'modifiedDate', label: 'ModifiedDate' },
            { key: 'actions', label: 'Actions' },
        ],
        documents: [],

        file: null,

        loading: false,
        loadingSignProcess: false,
        status: null,
        serviceSelected: null,
        signSelected: null,
        signProcess: [],
        loadingAddComment: false,
        comment: '',
        dataReport: {
            loanNumber: '',
            loanOfficer: '',
            applicantName: '',
            coApplicantName: '',
            applicantAddress: '',
            applicantIdNumber: '',
            principalBalance: '',
            apr: '',
            interest: '',
            totalInterestPrincipal: '',
            monthlyPayment: '',
            loanTermInMonths: '',
            lastMonthlyPayment: '',
            lastPaymentDate: '',
            firstPaymentDate: '',
            contractDate: '',
            loanOfficerName: '',
        },
        fileSelected: null,
        templates: [],

        options: [
            { text: 'CEDULA', value: 7 },
            { text: 'DRIVER LICENSE', value: 8 },
            { text: 'PASSPORT', value: 16 },
        ],

        applicantDocumentTypeIdForSign: -1,
        coApplicantDocumentTypeIdForSign: -1,
        guarantorDocumentTypeIdForSign: -1,

        disbursementRecipient: -1,
        disbursementBank: '',
        disbursementAccount: '',

        pobtiedTo: -1,
        employments: [],
        employerForPob: -1,

        loadingPrinting: false,
    }),

    methods: {
        async printContract() {
            this.loadingPrinting = true;
            const response = await this.$store.dispatch(
                'loan/contract-sign-process/printContract',
                this.loanApplication.id,
            );
            if (response.dataResult) {
                window.open(response.dataResult, '_blank');
            }
            this.loadingPrinting = false;
        },

        async viewDocument(item) {
            const id = item.id;
            if (!id) return;
            const response = await this.$store.dispatch('auth/getSignedUrl', {
                id,
                resourceType: 'loan-contract',
            });
            if (response?.dataResult) {
                const url = response.dataResult;
                window.open(url, '_blank');
            }
        },
        setDisbursement() {
            const applicant = this.applicants.find((item) => item.id == this.disbursementRecipient);
            if (applicant) {
                this.disbursementBank = applicant.additionalDetails.bankName;
                this.disbursementAccount = applicant.additionalDetails.bankAccountNumber;
            }
        },
        setTied() {
            const applicant = this.applicants.find((item) => item.id == this.pobtiedTo);
            if (applicant) {
                this.employments = applicant.employments;
            }
        },

        handleTied() {
            this.setTied();
            this.updateSignature();
        },

        handleDisbursement() {
            this.setDisbursement();
            this.updateSignature();
        },

        async getData() {
            await this.getSignProcess();
        },
        async getSignProcess() {
            const response = await this.$store.dispatch(
                'loan/contract-sign-process/get_signProcess',
                this.$route.params.id,
            );
            this.signProcess = response.dataResult;
        },
        async createSignProcess() {
            this.loadingSignProcess = true;
            await this.$store.dispatch(`loan/contract-sign-process/create_signProcess`, this.loanApplication.id);
            await this.getData();
            this.loadingSignProcess = false;
        },
        async updateSignature() {
            await this.$store.dispatch(`loan/applications/update_typeSignature`, {
                id: this.$route.params.id,
                data: {
                    isDigitalSignature: this.signSelected == 'digital',
                    applicantDocumentTypeIdForSign: this.applicantDocumentTypeIdForSign,
                    coApplicantDocumentTypeIdForSign: this.coApplicantDocumentTypeIdForSign,
                    guarantorDocumentTypeIdForSign: this.guarantorDocumentTypeIdForSign,
                    disbursementRecipient: this.disbursementRecipient,
                    pobtiedTo: this.pobtiedTo,
                    employerForPob: this.employerForPob,
                },
            });
            await this.getData();
        },

        setReportData() {
            this.dataReport = {
                loanNumber: this.loanApplication.id,
                loanOfficer: this.loanApplication.agentInfo.agentName,
                applicantName:
                    this.loanApplication.applicantInfo.firstName + ' ' + this.loanApplication.applicantInfo.lastName,
                coApplicantName:
                    this.loanApplication.coApplicantInfo.firstName +
                    ' ' +
                    this.loanApplication.coApplicantInfo.lastName,
                applicantAddress: this.loanApplication.applicantInfo.address1,
                applicantIdNumber: this.loanApplication.applicantInfo.id,
                principalBalance: this.loanApplication.summaryInfo.loanAmount,
                apr: this.loanApplication.summaryInfo.apr,
                interest: this.loanApplication.summaryInfo.interestRate,
                totalInterestPrincipal:
                    this.loanApplication.summaryInfo.totalAmount +
                    (this.loanApplication.summaryInfo.interestRate / 100) *
                        this.loanApplication.summaryInfo.totalAmount,
                monthlyPayment: this.loanApplication.summaryInfo.monthlyPayment,
                loanTermInMonths: this.loanApplication.summaryInfo.periodInMonths,
                lastMonthlyPayment: '',
                lastPaymentDate: '',
                firstPaymentDate: '',
                contractDate: this.loanApplication.createAt,
                loanOfficerName: this.loanApplication.agentInfo.agentName,
            };
        },

        async getTemplates() {
            const response = await this.$store.dispatch('loan/contracts/get_allTemplates', { page: 1, records: 100 });
            this.templates = response.dataResult;
        },
        async getDocuments() {
            const response = await this.$store.dispatch('loan/printing/get_allSignedDocuments');
            this.documents = response;
        },
        fileToBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result.split(',')[1]);
                reader.onerror = (error) => reject(error);
                reader.readAsDataURL(file);
            });
        },
        async uploadFile() {
            const file = await this.fileToBase64(this.file);
            const payload = {
                loanApplicationId: this.$route.params.id,
                fileName: this.file.name,
                fileBase64: file,
            };
            await this.$store.dispatch('loan/contract-sign-process/uploadContractSigned', payload);
            await this.getDocuments();
            await this.getSignProcess();
            this.$bvModal.hide('modal-upload-file');
        },

        showViewModal(item) {
            this.fileSelected = item;
            this.$bvModal.show('modal-view-file');
        },

        showDeleteModal(item) {
            this.$bvModal.show('modal-delete');
            this.fileSelected = item;
        },

        async deleteDocument() {
            await this.$store.dispatch('loan/printing/remove_signedDocument', this.fileSelected.id);
            await this.getDocuments();
            this.$bvModal.hide('modal-delete');
            this.fileId = null;
        },
    },
    computed: {
        ...mapGetters({
            process: 'customer/productRequest/getProcess',
            authorizations: 'auth/getAuthorizations',
            loanApplication: 'loan/applications/getLoanApplication',
        }),
        hasPermissionSign() {
            // return this.authorizations.includes('/LoanApplications-Signature');
            return true;
        },
        applicants() {
            const applicant = this.loanApplication.applicantInfo;
            const coApplicant =
                this.loanApplication.coApplicantInfo.id != 0 ? this.loanApplication.coApplicantInfo : null;
            const items = [];
            items.push(applicant);

            if (coApplicant) {
                items.push(coApplicant);
            }
            return items;
        },
        repaymentMethod() {
            return this.loanApplication.repaymentMethod == 1 ? 'CASH' : 'POB';
        },
    },
    created() {
        this.getSignProcess();
        this.setReportData();
        this.getData();
        this.getTemplates();
        this.getDocuments();
        this.signSelected = this.loanApplication.isDigitalSignature ? 'digital' : 'manual';
        this.applicantDocumentTypeIdForSign = this.loanApplication.applicantDocumentTypeIdForSign;
        this.coApplicantDocumentTypeIdForSign = this.loanApplication.coApplicantDocumentTypeIdForSign;
        this.guarantorDocumentTypeIdForSign = this.loanApplication.guarantorDocumentTypeIdForSign;

        this.disbursementRecipient = this.loanApplication.disbursementRecipient;
        this.pobtiedTo = this.loanApplication.pobtiedTo;
        this.employerForPob = this.loanApplication.employerForPob;

        this.setDisbursement();
        this.setTied();
    },
};
</script>
