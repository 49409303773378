<template>
    <b-overlay :show="loading" rounded="sm">
        <div>
            <b-card no-body class="">
                <template v-if="!readOnly">
                    <Header />
                    <hr class="mb-4" />
                </template>

                <div class="d-flex justify-content-end mb-3">
                    <b-button
                        variant="primary"
                        class="mr-2"
                        v-b-modal.modal-add-obligation
                        v-if="
                            $validatePermission('/LoanApplications-Add-ScreeningObligation') &&
                            loanApplication.loanProcessStatusLog.currentStatusCode != '220'
                        "
                    >
                        Add obligation
                    </b-button>
                </div>

                <div class="border" v-for="(item, idx) in items" :key="item.id" v-b-toggle="`accordion-${idx}`">
                    <h4 class="px-4 pt-4">
                        {{ item.type }}: <span style="font-weight: 500"> {{ item.customerName }}</span>
                    </h4>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr 1fr" class="px-4 pb-2">
                        <div>
                            <label class="mb-1">Screening send date: </label>
                            <p>{{ $formatDate(item.sendDate) }}</p>
                        </div>
                        <div>
                            <label class="mb-1">Screening status: </label>
                            <p>{{ item.status }}</p>
                        </div>
                        <div>
                            <label class="mb-1">Completion date: </label>
                            <p>{{ $formatDate(item.lastCompletionDate) }}</p>
                        </div>
                        <div>
                            <label class="mb-1">Requires new screening: </label>
                            <p>{{ item.requiresNewScreening }}</p>
                        </div>
                    </div>

                    <b-collapse :id="`accordion-${idx}`" class="w-100" :visible="item.type == 'Applicant'">
                        <div class="px-3 pb-2">
                            <b-table
                                style="max-height: 400px; width: 100%"
                                :items="item.screenings"
                                :fields="screeningFields"
                                small
                                show-empty
                                responsive
                                bordered
                                head-variant="light"
                                hover
                            >
                                <template v-slot:cell(isActive)="data">
                                    <b-badge :variant="data.item.isActive ? 'success' : 'danger'">{{
                                        data.item.isActive ? 'Active' : 'Inactive'
                                    }}</b-badge>
                                </template>
                                <template v-slot:cell(createDate)="data">
                                    {{ $formatDate(data.item.createDate) }}
                                </template>
                                <template v-slot:cell(modifiedDate)="data">
                                    {{ $formatDate(data.item.modifiedDate) }}
                                </template>
                                <template v-slot:cell(amount)="data">
                                    {{ $formatCurrency(data.item.amount) }}
                                </template>
                                <template v-slot:cell(montlyPayment)="data">
                                    {{ $formatCurrency(data.item.montlyPayment) }}
                                </template>

                                <template v-slot:cell(isGuarantor)="data">
                                    <b-badge :variant="data.item.isGuarantor ? 'success' : 'danger'">{{
                                        data.item.isGuarantor ? 'Yes' : 'No'
                                    }}</b-badge>
                                </template>

                                <template v-slot:cell(action)="data">
                                    <!-- <b-button @click="showModal(data.item)" variant="primary" size="sm"
                                        >Edit customer</b-button
                                    > -->
                                    <!-- Handle active Inactive -->
                                    <b-button
                                        @click.stop="
                                            data.item.isActive
                                                ? disabledVerification(data.item)
                                                : enableVerification(data.item)
                                        "
                                        :variant="data.item.isActive ? 'danger' : 'success'"
                                        size="sm"
                                    >
                                        {{ data.item.isActive ? 'Disable' : 'Enable' }}
                                    </b-button>
                                </template>
                            </b-table>
                        </div>
                    </b-collapse>
                </div>
            </b-card>

            <b-modal id="modal-add-obligation" hide-footer centered title="Financial Obligations" size="lg">
                <FinancialObligations />
            </b-modal>

            <!-- Modal disclaimer send Verifications -->
            <b-modal id="modal" hide-footer centered title="Confirm" size="sm">
                <b-card-text> Are you sure to want to sent verifications? </b-card-text>
                <div class="d-flex justify-content-end">
                    <b-button variant="secondary" class="mr-2" @click="$bvModal.hide('modal')">No</b-button>
                    <b-button variant="primary" @click="sendAllVerifications">
                        <b-spinner small v-if="loadingSendVerifications"></b-spinner>
                        Yes
                    </b-button>
                </div>
            </b-modal>

            <!-- Modal disable screening -->
            <b-modal id="modal-disable-screening" hide-footer centered title="Confirm" size="sm">
                <b-card-text> Are you sure to want to disable this screening? </b-card-text>
                <div class="d-flex justify-content-end">
                    <b-button variant="secondary" class="mr-2" @click="$bvModal.hide('modal-disable-screening')"
                        >No</b-button
                    >
                    <b-button variant="primary" @click="updateStatus(false)">
                        <b-spinner small v-if="loadingUpdateStatus"></b-spinner>
                        Yes
                    </b-button>
                </div>
            </b-modal>

            <!-- Modal enable screening -->
            <b-modal id="modal-enable-screening" hide-footer centered title="Confirm" size="sm">
                <b-card-text> Are you sure to want to enable this screening? </b-card-text>
                <div class="d-flex justify-content-end">
                    <b-button variant="secondary" class="mr-2" @click="$bvModal.hide('modal-enable-screening')"
                        >No</b-button
                    >
                    <b-button variant="primary" @click="updateStatus(true)">
                        <b-spinner small v-if="loadingUpdateStatus"></b-spinner>
                        Yes
                    </b-button>
                </div>
            </b-modal>
        </div>
    </b-overlay>
</template>

<script>
import Header from '@/components/Header.vue';
import FinancialObligations from '@/components/applications/FinancialObligations.vue';
import { mapGetters } from 'vuex';
export default {
    name: 'ApplicationScreening',
    components: {
        Header,
        FinancialObligations,
    },
    props: {
        new: Boolean,
        readOnly: Boolean,
    },
    data: () => ({
        page: {
            title: 'SeachTable',
        },
        filter: null,
        screeningFields: [
            {
                key: 'id',
                label: 'ID',
            },
            {
                key: 'createDate',
                label: 'Request Date',
            },
            {
                key: 'bankDescription',
                label: 'Bank',
            },
            {
                key: 'obligationTypeDescription',
                label: 'Loan Type',
            },

            {
                key: 'modifiedDate',
                label: 'Date Screened',
            },
            {
                key: 'amount',
                label: 'Balance',
            },
            {
                key: 'montlyPayment',
                label: 'Monthly Payment',
            },
            {
                key: 'isGuarantor',
                label: 'Is Guarantor',
            },
            {
                key: 'loanStatus',
                label: 'Loan Status',
            },

            {
                key: 'comment',
                label: 'Comments',
            },
            {
                key: 'isActive',
                label: 'Status',
            },
            {
                key: 'action',
                label: 'Action',
            },
        ],

        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 20, 40, { value: 100, text: 'Show a lot' }],
        screeningSelected: null,
        loading: false,
        loadingSendVerifications: false,
        loadingUpdateStatus: false,
        items: [],
    }),
    methods: {
        async getScreeningVerifications() {
            this.loading = true;
            await this.$store.dispatch('loan/applications/refreshApplicantsInfo', this.$route.params.id);
            this.loading = false;
        },
        async sendAllVerifications() {
            this.loadingSendVerifications = true;
            await this.$store.dispatch('customer/screening/send_Emails', this.$route.params.id);
            await this.getScreeningVerifications();
            this.loadingSendVerifications = false;
        },
        async updateStatus(status) {
            this.loadingUpdateStatus = true;
            await this.$store.dispatch('customer/screening/update_screeningStatus', {
                id: this.screeningSelected.id,
                isActive: status,
            });
            await this.getScreeningVerifications();
            this.screeningSelected = null;
            this.$bvModal.hide('modal-disable-screening');
            this.$bvModal.hide('modal-enable-screening');
            this.loadingUpdateStatus = false;
        },
        getLink(item) {
            //copy to clipboard
            const url = `${window.location.origin}/screening-verification/${item.guid}?id=${item.id}`;
            if (navigator.clipboard) {
                navigator.clipboard.writeText(url);
                this.$bvToast.toast(`Link copied to clipboard`, {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });
            }
        },
        disabledVerification(item) {
            this.screeningSelected = item;
            this.$bvModal.show('modal-disable-screening');
        },
        enableVerification(item) {
            this.screeningSelected = item;
            this.$bvModal.show('modal-enable-screening');
        },

        getDaysSinceLastDate(date) {
            if (date) {
                return 0;
            }
            const lastScreeningDate = new Date(date);
            const currentDate = new Date();
            const diffTime = Math.abs(currentDate - lastScreeningDate);
            const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
            return diffDays;
        },
        buildScreeningData(screenings) {
            const screeningBatchs = screenings?.screeningsBatchs;
            const daysLastScreeningDate = this.getDaysSinceLastDate(screeningBatchs?.lastCompletionDate);
            const requiresNewScreening = daysLastScreeningDate > 14 ? 'Yes' : 'No';
            return {
                sendDate: screeningBatchs?.date,
                status: screeningBatchs?.state || '',
                lastCompletionDate: screeningBatchs?.lastCompletionDate,
                daysLastScreeningDate,
                requiresNewScreening,
                screenings: screenings?.screeningsRecords,
            };
        },

        setItems() {
            const data = [];

            //Screening for Applicant
            if (this.loanApplication.applicantInfo) {
                const screening = {
                    screeningsBatchs: this.loanApplication.applicantInfo.screenings.screeningsBatch,
                    screeningsRecords: this.loanApplication.applicantInfo.screenings?.screeningsRecords,
                };
                data.push({
                    type: 'Applicant',
                    customerName: this.$buildName(this.loanApplication.applicantInfo),
                    ...this.buildScreeningData(screening),
                });
            }

            //Screening for Applicant Spouse
            const spousesInfo = this.loanApplication.applicantInfo?.additionalDetails?.spousesInfo;
            if (spousesInfo && spousesInfo.screenings) {
                const screening = {
                    screeningsBatchs: spousesInfo.screenings?.screeningsBatch,
                    screeningsRecords: spousesInfo.screenings?.screeningsRecords ?? [],
                };

                data.push({
                    type: 'Applicant Spouse',
                    customerName: this.$buildName(spousesInfo),
                    ...this.buildScreeningData(screening),
                });
            }

            //coApplicantInfo
            if (this.loanApplication.coApplicantInfo?.screenings) {
                const screening = {
                    screeningsBatchs: this.loanApplication.coApplicantInfo.screenings.screeningsBatch,
                    screeningsRecords: this.loanApplication.coApplicantInfo.screenings?.screeningsRecords,
                };

                data.push({
                    type: 'CoApplicant',
                    customerName: this.$buildName(this.loanApplication.coApplicantInfo),
                    ...this.buildScreeningData(screening),
                });
            }

            const spousesCoApplicantInfo = this.loanApplication.coApplicantInfo?.additionalDetails?.spousesInfo;
            if (spousesCoApplicantInfo && spousesCoApplicantInfo.screenings) {
                const screening = {
                    screeningsBatchs: spousesCoApplicantInfo.screenings?.screeningsBatch,
                    screeningsRecords: spousesCoApplicantInfo.screenings?.screeningsRecords ?? [],
                };

                data.push({
                    type: 'CoApplicant Spouse',
                    customerName: this.$buildName(spousesCoApplicantInfo),
                    ...this.buildScreeningData(screening),
                });
            }

            //guarantorInfo
            if (this.loanApplication.guarantorInfo?.screenings) {
                const screening = {
                    screeningsBatchs: this.loanApplication.guarantorInfo.screenings.screeningsBatch,
                    screeningsRecords: this.loanApplication.guarantorInfo.screenings?.screeningsRecords,
                };

                data.push({
                    type: 'Guarantor',
                    customerName: this.$buildName(this.loanApplication.guarantorInfo),
                    ...this.buildScreeningData(screening),
                });
            }

            const spousesGuarantorInfo = this.loanApplication.guarantorInfo?.additionalDetails?.spousesInfo;
            if (spousesGuarantorInfo && spousesGuarantorInfo.screenings) {
                const screening = {
                    screeningsBatchs: spousesGuarantorInfo.screenings?.screeningsBatch,
                    screeningsRecords: spousesGuarantorInfo.screenings?.screeningsRecords ?? [],
                };

                data.push({
                    type: 'Guarantor Spouse',
                    customerName: this.$buildName(spousesGuarantorInfo),
                    ...this.buildScreeningData(screening),
                });
            }

            this.items = data;
        },
    },
    computed: {
        ...mapGetters({
            authorizations: 'auth/getAuthorizations',
            loanApplication: 'loan/applications/getLoanApplication',
        }),
        screening() {
            return this.loanApplication?.screening ?? [];
        },
    },
    mounted() {},
    created() {
        this.setItems();
    },
};
</script>
