<template>
    <b-row>
        <b-col cols="12">
            <b-card no-body class="mb-4">
                <b-card-body class="p-3">
                    <Header />
                    <hr class="mb-4" />

                    <div class="p-4 bg-light d-flex flex-column" style="row-gap: 16px">
                        <div class="row d-flex" style="row-gap: 16px">
                            <div class="col-12 col-lg-6 col-xl-4">
                                <b-card class="h-100">
                                    <h3 class="mb-2">Application Info</h3>
                                    <div class="row mb-4" v-if="loanApplication" style="row-gap: 18px">
                                        <div class="col-6">
                                            <label class="fw-medium mb-1">Amount</label>
                                            <p class="mb-0">{{ $formatCurrency(loanApplication.loanAmount) }}</p>
                                        </div>
                                        <div class="col-6">
                                            <label class="fw-medium mb-1">Installments</label>
                                            <p class="mb-0">{{ loanApplication.periodInMonths }}</p>
                                        </div>
                                        <div class="col-6">
                                            <label class="fw-medium mb-1">Monthly Payment</label>
                                            <p class="mb-0">{{ $formatCurrency(loanApplication.monthlyPayment) }}</p>
                                        </div>
                                        <div class="col-6">
                                            <label class="fw-medium mb-1">Total expected amount</label>
                                            <p class="mb-0">
                                                {{ $formatCurrency(loanApplication.totalExpectedAmount) }}
                                            </p>
                                        </div>
                                        <div class="col-6">
                                            <label class="fw-medium mb-1">APR</label>
                                            <p class="mb-0">{{ loanApplication.apr }}%</p>
                                        </div>
                                        <div class="col-6">
                                            <label class="fw-medium mb-1">Dynamic Interest Rate</label>
                                            <p class="mb-0">{{ loanApplication.interestRate }}%</p>
                                        </div>
                                    </div>
                                </b-card>
                            </div>
                            <div class="col-12 col-lg-6 col-xl-8">
                                <b-card>
                                    <div class="d-flex justify-content-between align-items-center mb-3">
                                        <h3>Preview Schedule</h3>

                                        <b-button
                                            variant="primary"
                                            v-b-modal.modal-preview-schedule
                                            v-if="loanApplication.loanProcessStatusLog.currentStatusCode != '220'"
                                        >
                                            <b-spinner small v-if="loadingSchedule"></b-spinner>
                                            <span v-if="isPreview">Update Schedule</span>
                                            <span v-else>Create New Schedule</span>
                                        </b-button>
                                    </div>

                                    <b-table
                                        style="min-height: 250px; max-height: 300px; overflow-y: scroll"
                                        show-empty
                                        responsive
                                        small
                                        class="mb-0 mt-2"
                                        head-variant="light"
                                        :filter="null"
                                        :items="schedules"
                                        :fields="fields"
                                        hover
                                    >
                                        <template #cell(DueDate)="data">
                                            {{ data.item.DueDate && data.item.DueDate.split('T')[0] }}</template
                                        >
                                        <template #cell(payment)="data">
                                            {{ $formatCurrency(data.item.Principal.Amount.Expected) }}
                                        </template>
                                        <template #cell(interest)="data">
                                            {{ $formatCurrency(data.item.Interest.Amount.Expected) }}
                                        </template>
                                    </b-table>
                                </b-card>
                            </div>
                        </div>
                        <div class="row">
                            <!-- <div class="col-12 col-lg-6">
                                <b-card class="h-100">
                                    <h3 class="mb-3">OCS Approval Information</h3>

                                    <b-list-group v-if="process">
                                        <b-list-group-item
                                            class="d-flex justify-content-between align-items-center bg-light"
                                        >
                                            <div class="d-flex align-items-center">
                                                <h5 class="mr-2 mb-0">Approve Level 1</h5>
                                                <b-badge :variant="process.approvalLvl1 ? 'success' : ''">
                                                    {{ process.approvalLvl1 ? 'Approved' : 'Pending' }}
                                                </b-badge>
                                            </div>
                                            <b-button
                                                v-if="hasPermissionLvl1"
                                                :variant="process.approvalLvl1 ? 'danger' : 'primary'"
                                                v-b-modal.modal-update-status
                                                @click="
                                                    (serviceSelected = 'ApprovalLvl1'),
                                                        (status = !process.approvalLvl1),
                                                        (level = 'Level 1')
                                                "
                                                >{{ process.approvalLvl1 ? 'Revoke' : 'Verify' }}</b-button
                                            >
                                        </b-list-group-item>

                                        <b-list-group-item class="d-flex justify-content-between align-items-center">
                                            <div class="d-flex align-items-center">
                                                <h5 class="mr-2 mb-0">Approve Level 1.2</h5>
                                                <b-badge :variant="process.approvalLvl1_2 ? 'success' : ''">
                                                    {{ process.approvalLvl1_2 ? 'Approved' : 'Pending' }}
                                                </b-badge>
                                            </div>
                                            <b-button
                                                v-if="hasPermissionLvl1p1"
                                                :variant="process.approvalLvl1_2 ? 'danger' : 'primary'"
                                                v-b-modal.modal-update-status
                                                @click="
                                                    (serviceSelected = 'ApprovalLvl1_2'),
                                                        (status = !process.approvalLvl1_2),
                                                        (level = 'Level 1.2')
                                                "
                                                >{{ process.approvalLvl1_2 ? 'Revoke' : 'Verify' }}</b-button
                                            >
                                        </b-list-group-item>

                                        <b-list-group-item
                                            v-if="loanApplication && loanApplication.loanAmount >= 20000"
                                            class="d-flex justify-content-between align-items-center bg-light"
                                        >
                                            <div class="d-flex align-items-center">
                                                <h5 class="mr-2 mb-0">Approve Level 2</h5>
                                                <b-badge :variant="process.approvalLvl2 ? 'success' : ''">
                                                    {{ process.approvalLvl2 ? 'Approved' : 'Pending' }}
                                                </b-badge>
                                            </div>
                                            <b-button
                                                v-if="hasPermissionLvl2"
                                                :variant="process.approvalLvl2 ? 'danger' : 'primary'"
                                                v-b-modal.modal-update-status
                                                @click="
                                                    (serviceSelected = 'ApprovalLvl2'),
                                                        (status = !process.approvalLvl2),
                                                        (level = 'Level 2')
                                                "
                                                >{{ process.approvalLvl2 ? 'Revoke' : 'Verify' }}</b-button
                                            >
                                        </b-list-group-item>
                                    </b-list-group>
                                </b-card>
                            </div> -->
                            <div
                                class="col-12 col-lg-6 col-xl-4"
                                v-if="loanApplication.loanProcessStatusLog.currentStatusCode != '220'"
                            >
                                <b-card class="h-100">
                                    <h3 class="mb-2">Post-Control Actions</h3>
                                    <b-list-group>
                                        <b-list-group-item
                                            class="d-flex justify-content-between align-items-center bg-light"
                                        >
                                            <div class="d-flex align-items-center">
                                                <h5 class="mr-2 mb-0">Create Loan</h5>
                                            </div>
                                            <!-- <b-button
                                                variant="primary"
                                                @click="createMambuCustomer"
                                                :disabled="(loanApplication.processStatusCode != '100' &&
                                                            loanApplication.processStatusCode != '110') ||
                                                            loadingMambu"
                                            > -->
                                            <b-button
                                                variant="primary"
                                                @click="createMambuCustomer"
                                                :disabled="loanApplication.processStatusCode != '195' || loadingMambu"
                                            >
                                                <b-spinner small v-if="loadingMambu"></b-spinner>
                                                Submit
                                            </b-button>
                                        </b-list-group-item>
                                    </b-list-group>
                                </b-card>
                            </div>
                        </div>
                    </div>
                </b-card-body>
            </b-card>

            <b-modal id="modal-update-status" hide-footer centered title="Confirm" size="sm">
                <b-card-text> Are you sure to want to update status for {{ level }}? </b-card-text>
                <div class="d-flex justify-content-end">
                    <b-button variant="secondary" class="mr-2" @click="$bvModal.hide('modal-update-status')"
                        >No</b-button
                    >
                    <b-button variant="primary" :disabled="loading" @click="updateStatus(serviceSelected, status)">
                        <b-spinner small v-if="loading" class="mr-1"></b-spinner>
                        Yes
                    </b-button>
                </div>
            </b-modal>

            <b-modal id="modal-comment-approval" hide-footer centered title="Add a new comment" @close="clearData">
                <b-form @submit.prevent="addComment">
                    <b-form-group label="Comment" label-class="fw-medium mb-1" label-for="input-2">
                        <b-form-textarea
                            required
                            id="input-2"
                            placeholder="Your comment here"
                            rows="3"
                            no-resize
                            v-model="comment"
                        ></b-form-textarea>
                    </b-form-group>
                    <b-button block variant="primary" type="submit" :disabled="loadingAddComment"
                        >Save Comment</b-button
                    >
                </b-form>
            </b-modal>

            <b-modal id="modal-preview-schedule" hide-footer centered title="Confirm" size="sm">
                <b-card-text v-if="isPreview">Are you sure you want to update the schedule?</b-card-text>
                <b-card-text v-else> Are you sure you want to create a new schedule?</b-card-text>
                <div class="d-flex justify-content-end">
                    <b-button variant="secondary" class="mr-2" @click="$bvModal.hide('modal-preview-schedule')"
                        >No</b-button
                    >
                    <b-button variant="primary" :disabled="loadingSchedule" @click="changeSchedule">
                        <b-spinner class="mr-1" small v-if="loadingSchedule"></b-spinner>
                        Yes
                    </b-button>
                </div>
            </b-modal>
        </b-col>
    </b-row>
</template>

<script>
import Header from '@/components/Header.vue';
import { mapGetters } from 'vuex';
import { validatePermission } from '../../utils/auth';
export default {
    name: 'ApplicationLoanSetup',
    components: {
        Header,
    },

    data: () => ({
        loading: false,
        loadingMambu: false,
        loadingState: false,
        loadingFunds: false,
        loadingSchedule: false,

        status: null,
        serviceSelected: null,
        level: '',
        loan: null,

        fields: [
            {
                key: 'Number',
                sortable: true,
            },
            {
                key: 'payment',
                label: 'Expected Payment',
                sortable: true,
            },
            {
                key: 'interest',
                label: 'Expected Interest',
                sortable: true,
            },
            {
                key: 'State',
                sortable: true,
            },
            {
                key: 'DueDate',
                label: 'Date',
                sortable: true,
            },
        ],
        schedules: [],
        loadingAddComment: false,
        comment: '',
    }),
    methods: {
        async updateStatus(service, status) {
            if (this.status != null && this.serviceSelected != null) this.loading = true;
            if (service == 'ApprovalLvl1_2' && this.loanApplication.loanAmount < 20000) {
                await this.$store.dispatch(`customer/productRequest/update_status${'ApprovalLvl2'}`, {
                    id: this.$route.params.id,
                    status,
                });
            }

            await this.$store.dispatch(`customer/productRequest/update_status${service}`, {
                id: this.$route.params.id,
                status: status,
            });
            await this.getData();
            this.loading = false;
            this.$bvModal.hide('modal-update-status');
            this.level = '';
            this.serviceSelected = null;
            this.status = null;
        },

        async getData() {
            await this.getCreatedLoans();
        },
        async getCreatedLoans() {
            const response = await this.$store.dispatch('mambu/get_byLoanApplicationId', this.$route.params.id);
            this.loan = response.data.dataResult;

            if (this.loan?.previewSchedulePayload) {
                const data = this.loan.previewSchedulePayload;
                const dataParsed = JSON.parse(data);
                this.schedules = dataParsed.Installments;
            }
        },
        async createMambuCustomer() {
            this.loadingMambu = true;

            if (this.customer.encodedKey == null || this.customer.encodedKey == '') {
                await this.$store.dispatch('mambu/create_mambuCustomer', this.loanApplication.applicantInfo.id);
            } else {
                await this.$store.dispatch('mambu/update_mambuCustomer', this.loanApplication.applicantInfo.id);
            }

            if (this.loanApplication.serieType == 'AP' || this.loanApplication.serieType == 'AV') {
                await this.$store.dispatch('mambu/create_mambuLoan', {
                    loanApplicationId: this.$route.params.id,
                    customerId: this.loanApplication.applicantInfo.id,
                });
            } else if (this.loanApplication.serieType == 'AR') {
                await this.$store.dispatch('mambu/refinance', {
                    loanApplicationId: this.$route.params.id,
                });
            } else if (this.loanApplication.serieType == 'AU') {
                await this.$store.dispatch('mambu/reschedule', {
                    loanApplicationId: this.$route.params.id,
                });
            }

            await this.getData();
            this.loadingMambu = false;
        },

        async changeSchedule() {
            this.loadingSchedule = true;

            const response = await this.$store.dispatch('mambu/preview_schedule', {
                loanId: this.loan ? this.loan.loanId : '',
                loanApplicationId: this.$route.params.id,
            });

            if (!this.loan) {
                const data = response?.data?.dataResult?.payload?.installments ?? [];
                this.schedules = data.map((el) => {
                    return {
                        DueDate: el.dueDate,
                        Interest: {
                            Amount: {
                                Expected: el.interest.amount.expected,
                            },
                        },
                        Principal: {
                            Amount: {
                                Expected: el.principal.amount.expected,
                            },
                        },
                        Number: el.number,
                        State: el.state,
                    };
                });
            }

            await this.getData();
            this.$bvModal.hide('modal-preview-schedule');
            this.loadingSchedule = false;
        },
        handleStatus(status) {
            if (status == 'INIT') {
                return {
                    icon: 'check-circle',
                    color: 'text-sucess',
                };
            }
        },
        async addComment() {
            this.loadingAddComment = true;

            await this.$store.dispatch('customer/comments/add_comment', {
                comment: this.comment,
                customerId: this.customer.additionalDetails.customerId,
                loanApplicationId: this.loanApplication.applicantInfo.id,
                loanApplication: 'loan/applications/getLoanApplication',
            });

            await this.getComments();
            this.$bvModal.hide('modal-comment-approval');
            this.comment = '';
            this.loadingAddComment = false;
        },
        clearData() {
            this.comment = '';
            this.commentSelected = null;
        },
        getComments() {
            if (this.$route.path.includes('/applications')) {
                this.$store.dispatch(
                    'customer/comments/get_commentsByLoanAppId',
                    this.loanApplication.applicantInfo.id,
                );
            }
        },
    },
    computed: {
        ...mapGetters({
            process: 'customer/productRequest/getProcess',
            customer: 'customer/getCustomer',
            authorizations: 'auth/getAuthorizations',
            loanApplication: 'loan/applications/getLoanApplication',
        }),
        expectedFee() {
            if (!this.loanApplication) return 0;
            return 0;
        },
        isValidState() {
            if (this.loan && this.loan.accountState != 'PENDING_APPROVAL') {
                return true;
            }
            return false;
        },
        isPreview() {
            return this.loan && this.previewSchedulePayload;
        },
        hasPermissionTransfer() {
            return this.authorizations.includes('/LoanApplications-Transfer-Of-Founds');
        },
        hasPermissionCreateLoan() {
            return validatePermission('/LoanApplications-Create');
        },
        hasPermissionLvl1() {
            return this.authorizations.includes('/LoanApplications-Approval-1');
        },
        hasPermissionLvl1p1() {
            return this.authorizations.includes('/LoanApplications-Approval-1.2');
        },
        hasPermissionLvl2() {
            return this.authorizations.includes('/LoanApplications-Approval-2');
        },
    },
    created() {
        this.getCreatedLoans();
    },
};
</script>
