<template>
    <b-row>
        <b-col cols="12">
            <b-card no-body class="mb-4">
                <b-card-body class="p-3">
                    <Header />
                    <hr class="mb-2" />
                    <div class="row">
                        <div class="col-12 col-lg-6 col-xl-4 border-right">
                            <SummaryCustomer customerType="applicant" />
                        </div>
                        <div class="col-12 col-lg-6 col-xl-4 border-right">
                            <SummaryCustomer customerType="coApplicant" />
                        </div>
                        <div class="col-12 col-lg-6 col-xl-4">
                            <SummaryCustomer customerType="guarantor" />
                        </div>
                    </div>

                    <!-- <div class="p-3">
                        <div class="d-flex justify-content-end mt-3" v-if="hasPermission">
                            <b-button
                                size="sm"
                                :disabled="loading"
                                :variant="process.additionalDetails ? 'danger' : 'primary'"
                                @click="updateStatus('AdditionalDetails', !process.additionalDetails)"
                            >
                                {{
                                    process.additionalDetails
                                        ? 'Revoke Additional Details Verification'
                                        : 'Verify Additional Details'
                                }}</b-button
                            >
                        </div>
                    </div> -->
                    <hr class="mb-1" />

                    <div class="my-4">
                        <b-row>
                            <b-col class="col-12 col-lg-5 border-right pr-4">
                                <h4 class="text-center mb-4">New Loan Request</h4>
                                <b-row>
                                    <b-col cols="6">
                                        <label for="">Loan Purpose</label>
                                    </b-col>
                                    <b-col cols="6">
                                        <ItemText
                                            class="text-right"
                                            :text="loanApplication.summaryInfo.purposeDescription"
                                        />
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="6">
                                        <label for="">Purpose Details</label>
                                    </b-col>
                                    <b-col cols="6">
                                        <ItemText
                                            class="text-right"
                                            :text="loanApplication.summaryInfo.purposeDetails"
                                        />
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="6">
                                        <label for="">Loan Amount</label>
                                    </b-col>
                                    <b-col cols="6">
                                        <ItemText
                                            class="text-right"
                                            :text="$formatCurrency(loanApplication.summaryInfo.loanAmount)"
                                        />
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="6">
                                        <label for="">Administrative & Closing Fee</label>
                                    </b-col>
                                    <b-col cols="6">
                                        <ItemText
                                            class="text-right"
                                            :text="$formatCurrency(loanApplication.summaryInfo.adminFee)"
                                        />
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="6">
                                        <label for="">Total</label>
                                    </b-col>
                                    <b-col cols="6">
                                        <ItemText
                                            class="text-right"
                                            :text="$formatCurrency(loanApplication.summaryInfo.totalAmount)"
                                        />
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="6">
                                        <label for="">Durations (months)</label>
                                    </b-col>
                                    <b-col cols="6">
                                        <ItemText
                                            class="text-right"
                                            :text="loanApplication.summaryInfo.periodInMonths"
                                        />
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="6">
                                        <label for="">Loan Interest</label>
                                    </b-col>
                                    <b-col cols="6">
                                        <ItemText
                                            class="text-right"
                                            :text="loanApplication.summaryInfo.interestRate + '%'"
                                        />
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="6">
                                        <label for="">APR</label>
                                    </b-col>
                                    <b-col cols="6">
                                        <ItemText class="text-right" :text="loanApplication.summaryInfo.apr + '%'" />
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="6">
                                        <label for="">Monthly Payment</label>
                                    </b-col>
                                    <b-col cols="6">
                                        <ItemText
                                            class="text-right"
                                            :text="$formatCurrency(loanApplication.summaryInfo.monthlyPayment)"
                                        />
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="6">
                                        <label for="">Total Net Income</label>
                                    </b-col>
                                    <b-col cols="6">
                                        <ItemText
                                            class="text-right"
                                            :text="$formatCurrency(loanApplication.summaryInfo.totalNetIncome)"
                                        />
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="6">
                                        <label for="">Total Debt</label>
                                    </b-col>
                                    <b-col cols="6">
                                        <ItemText
                                            class="text-right"
                                            :text="$formatCurrency(loanApplication.summaryInfo.totalDebt)"
                                        />
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="6">
                                        <label for="">Remaining Income</label>
                                    </b-col>
                                    <b-col cols="6">
                                        <ItemText
                                            class="text-right"
                                            :text="$formatCurrency(loanApplication.summaryInfo.remainingIncome)"
                                        />
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="6">
                                        <label for="">Debt Ratio</label>
                                    </b-col>
                                    <b-col cols="6">
                                        <ItemText
                                            class="text-right"
                                            :text="loanApplication.summaryInfo.debtRatio + '%'"
                                        />
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col class="col-12 col-lg-7 pl-4">
                                <h4 class="text-center mb-4">Credits Score</h4>
                                <b-row>
                                    <b-col cols="6">
                                        <strong>Applicant:</strong>
                                        <ul class="pl-3 border-bottom">
                                            <li v-for="employment in employments" :key="employment.id" class="mb-1">
                                                {{ employment.employerName }} -
                                                {{ $formatCurrency(employment.monthlySalary) }}
                                            </li>
                                        </ul>
                                        <div>
                                            <b-row class="mb-2 align-items-center">
                                                <b-col cols="6"><strong>Total Salary:</strong></b-col>
                                                <b-col cols="6" class="text-right bg-blue">{{
                                                    $formatCurrency(totalSalary)
                                                }}</b-col>
                                            </b-row>
                                            <b-row class="mb-2 align-items-center">
                                                <b-col cols="6"><strong>Term (remaining):</strong></b-col>
                                                <b-col cols="6" class="text-right bg-blue">{{
                                                    creditScore.termRemaining
                                                }}</b-col>
                                            </b-row>
                                            <b-row class="mb-2 align-items-center">
                                                <b-col cols="6"><strong>DSCR:</strong></b-col>
                                                <b-col cols="6" class="text-right bg-blue">
                                                    <span v-if="creditScore.dscr">{{
                                                        creditScore.dscr.toFixed(2)
                                                    }}</span>
                                                </b-col>
                                            </b-row>
                                            <b-row class="mb-2 align-items-center">
                                                <b-col cols="6"><strong>Payment method:</strong></b-col>
                                                <b-col cols="6" class="text-right bg-blue">{{
                                                    creditScore.paymentMethod
                                                }}</b-col>
                                            </b-row>

                                            <b-row class="mb-2 align-items-center">
                                                <b-col cols="6"><strong>Credit Score:</strong></b-col>
                                                <b-col
                                                    cols="6"
                                                    class="text-right"
                                                    :style="{ background: hexColorIndicatorScore }"
                                                >
                                                    <div class="text-dark p-1">{{ creditScore.score }}</div>
                                                </b-col>
                                            </b-row>
                                        </div>
                                    </b-col>
                                    <b-col cols="6">
                                        <b-card class="mt-4">
                                            <b-row>
                                                <b-col cols="8"><strong>Rules</strong></b-col>
                                                <b-col cols="4"><strong>APR</strong></b-col>
                                            </b-row>
                                            <b-row v-for="(rule, index) in rules" :key="index">
                                                <b-col cols="4">
                                                    <div
                                                        :style="{ background: rule.hexColorIndicator }"
                                                        class="p-1 text-dark text-center"
                                                    >
                                                        <strong>{{ rule.minScore }} - {{ rule.maxScore }}</strong>
                                                    </div>
                                                </b-col>
                                                <b-col cols="4" class="d-flex align-items-center">{{
                                                    rule.description
                                                }}</b-col>
                                                <b-col cols="4" class="d-flex align-items-center">
                                                    {{ rule.minApr }} - {{ rule.maxApr }}
                                                </b-col>
                                            </b-row>
                                        </b-card>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </div>

                    <hr class="mb-1" />

                    <div class="my-4">
                        <h4 class="mb-3 text-center">Individual Debt Ratios / Remaining Income</h4>
                        <b-row>
                            <b-col cols="12" lg="4">
                                <SummaryDetail customerType="applicant" />
                            </b-col>
                            <b-col cols="12" lg="4">
                                <SummaryDetail customerType="coApplicant" />
                            </b-col>
                            <b-col cols="12" lg="4">
                                <SummaryDetail customerType="guarantor" />
                            </b-col>
                        </b-row>
                    </div>

                    <!-- <div class="d-flex justify-content-end mt-3 p-4" v-if="hasPermission">
                        <b-button
                            :disabled="loading"
                            :variant="process.verifyAllInformation ? 'danger' : 'primary'"
                            @click="updateStatus('VerifyAllInformation', !process.verifyAllInformation)"
                            >{{ process.verifyAllInformation ? 'Revoke' : 'Verify all Information' }}</b-button>
                    </div> -->
                </b-card-body>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import Header from '@/components/Header.vue';

import { mapGetters } from 'vuex';
import ItemText from '../ItemText.vue';
import SummaryCustomer from './summary/SummaryCustomer.vue';
import SummaryDetail from './summary/SummaryDetail.vue';
export default {
    name: 'ApplicationSummary',
    components: {
        Header,
        ItemText,
        SummaryCustomer,
        SummaryDetail,
    },
    data: () => ({
        loading: false,
        rules: [],
    }),
    methods: {
        async getData() {
            await this.$store.dispatch('customer/get_customer', this.loanApplication.applicantInfo.id);
            const response = await this.$store.dispatch('customer/scans/get_creditScoreRules');
            this.rules = response || [];
        },
        async updateStatus(service, status) {
            this.loading = true;
            const action = `productRequest/update_status${service}`;
            await this.$store.dispatch(action, {
                id: this.$route.params.id,
                status: status,
            });
            await this.getData();
            this.loading = false;
        },

        getCreditRating(score) {
            if (score >= 800) return 'Excellent';
            if (score >= 740) return 'Very Good';
            if (score >= 670) return 'Good';
            if (score >= 580) return 'Fair';
            return 'Poor';
        },
        getCreditScoreClass(score) {
            if (score >= 800) return 'bg-success text-white p-4';
            if (score >= 740) return 'bg-success text-white p-4';
            if (score >= 670) return 'bg-warning text-dark p-4';
            if (score >= 580) return 'bg-danger text-white p-4';
            return 'bg-danger text-white p-4';
        },
    },
    computed: {
        ...mapGetters({
            process: 'customer/productRequest/getProcess',
            authorizations: 'auth/getAuthorizations',
            loanApplication: 'loan/applications/getLoanApplication',
        }),
        hasPermission() {
            return this.authorizations.includes('/LoanApplications-Verify-Information');
        },
        totalIncomes() {
            const incomes = this.loanApplication.incomes || [];
            return incomes.reduce((acc, curr) => acc + curr.amount, 0);
        },

        employments() {
            return this.loanApplication?.applicantInfo?.employments || [];
        },
        creditScore() {
            return this.loanApplication?.creditScore || null;
        },
        totalSalary() {
            return this.employments.reduce((acc, curr) => acc + curr.monthlySalary, 0);
        },
        //get hexColorIndicator from rules based on creditScore.score
        hexColorIndicatorScore() {
            const rule = this.rules.find(
                (rule) => this.creditScore.score >= rule.minScore && this.creditScore.score <= rule.maxScore,
            );
            return rule?.hexColorIndicator || '#dfe6e9';
        },
    },
    created() {
        this.getData();
    },
};
</script>

<style>
.bg-blue {
    background-color: #d0f2ff;
}
</style>
